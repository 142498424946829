exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-book-a-demo-js": () => import("./../../../src/pages/book-a-demo.js" /* webpackChunkName: "component---src-pages-book-a-demo-js" */),
  "component---src-pages-book-demo-js": () => import("./../../../src/pages/book-demo.js" /* webpackChunkName: "component---src-pages-book-demo-js" */),
  "component---src-pages-brand-js": () => import("./../../../src/pages/brand.js" /* webpackChunkName: "component---src-pages-brand-js" */),
  "component---src-pages-buy-with-prime-js": () => import("./../../../src/pages/buy-with-prime.js" /* webpackChunkName: "component---src-pages-buy-with-prime-js" */),
  "component---src-pages-calculating-app-actions-js": () => import("./../../../src/pages/calculating-app-actions.js" /* webpackChunkName: "component---src-pages-calculating-app-actions-js" */),
  "component---src-pages-can-alloy-do-x-js": () => import("./../../../src/pages/can-alloy-do-x.js" /* webpackChunkName: "component---src-pages-can-alloy-do-x-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-competitor-overview-js": () => import("./../../../src/pages/competitor-overview.js" /* webpackChunkName: "component---src-pages-competitor-overview-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cost-savings-js": () => import("./../../../src/pages/cost-savings.js" /* webpackChunkName: "component---src-pages-cost-savings-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-data-privacy-js": () => import("./../../../src/pages/data-privacy.js" /* webpackChunkName: "component---src-pages-data-privacy-js" */),
  "component---src-pages-dpa-js": () => import("./../../../src/pages/dpa.js" /* webpackChunkName: "component---src-pages-dpa-js" */),
  "component---src-pages-dtc-launch-checklist-js": () => import("./../../../src/pages/dtc-launch-checklist.js" /* webpackChunkName: "component---src-pages-dtc-launch-checklist-js" */),
  "component---src-pages-ebook-js": () => import("./../../../src/pages/ebook.js" /* webpackChunkName: "component---src-pages-ebook-js" */),
  "component---src-pages-ecommerce-automation-playbook-js": () => import("./../../../src/pages/ecommerce-automation-playbook.js" /* webpackChunkName: "component---src-pages-ecommerce-automation-playbook-js" */),
  "component---src-pages-embedded-faq-js": () => import("./../../../src/pages/embedded-faq.js" /* webpackChunkName: "component---src-pages-embedded-faq-js" */),
  "component---src-pages-embedded-features-js": () => import("./../../../src/pages/embedded-features.js" /* webpackChunkName: "component---src-pages-embedded-features-js" */),
  "component---src-pages-embedded-in-the-wild-js": () => import("./../../../src/pages/embedded-in-the-wild.js" /* webpackChunkName: "component---src-pages-embedded-in-the-wild-js" */),
  "component---src-pages-embedded-js": () => import("./../../../src/pages/embedded.js" /* webpackChunkName: "component---src-pages-embedded-js" */),
  "component---src-pages-embedded-playbook-js": () => import("./../../../src/pages/embedded-playbook.js" /* webpackChunkName: "component---src-pages-embedded-playbook-js" */),
  "component---src-pages-end-user-terms-js": () => import("./../../../src/pages/end-user-terms.js" /* webpackChunkName: "component---src-pages-end-user-terms-js" */),
  "component---src-pages-enterprise-ipaas-js": () => import("./../../../src/pages/enterprise-ipaas.js" /* webpackChunkName: "component---src-pages-enterprise-ipaas-js" */),
  "component---src-pages-features-alloy-modal-js": () => import("./../../../src/pages/features/alloy-modal.js" /* webpackChunkName: "component---src-pages-features-alloy-modal-js" */),
  "component---src-pages-features-custom-connector-js": () => import("./../../../src/pages/features/custom-connector.js" /* webpackChunkName: "component---src-pages-features-custom-connector-js" */),
  "component---src-pages-features-customization-js": () => import("./../../../src/pages/features/customization.js" /* webpackChunkName: "component---src-pages-features-customization-js" */),
  "component---src-pages-features-data-destinations-js": () => import("./../../../src/pages/features/data-destinations.js" /* webpackChunkName: "component---src-pages-features-data-destinations-js" */),
  "component---src-pages-features-data-transformations-js": () => import("./../../../src/pages/features/data-transformations.js" /* webpackChunkName: "component---src-pages-features-data-transformations-js" */),
  "component---src-pages-features-error-handling-js": () => import("./../../../src/pages/features/error-handling.js" /* webpackChunkName: "component---src-pages-features-error-handling-js" */),
  "component---src-pages-features-installation-manager-js": () => import("./../../../src/pages/features/installation-manager.js" /* webpackChunkName: "component---src-pages-features-installation-manager-js" */),
  "component---src-pages-features-large-data-transfers-js": () => import("./../../../src/pages/features/large-data-transfers.js" /* webpackChunkName: "component---src-pages-features-large-data-transfers-js" */),
  "component---src-pages-features-oem-js": () => import("./../../../src/pages/features/oem.js" /* webpackChunkName: "component---src-pages-features-oem-js" */),
  "component---src-pages-features-platform-infrastructure-js": () => import("./../../../src/pages/features/platform-infrastructure.js" /* webpackChunkName: "component---src-pages-features-platform-infrastructure-js" */),
  "component---src-pages-features-platform-security-js": () => import("./../../../src/pages/features/platform-security.js" /* webpackChunkName: "component---src-pages-features-platform-security-js" */),
  "component---src-pages-features-real-time-data-flow-js": () => import("./../../../src/pages/features/real-time-data-flow.js" /* webpackChunkName: "component---src-pages-features-real-time-data-flow-js" */),
  "component---src-pages-flow-features-js": () => import("./../../../src/pages/flow-features.js" /* webpackChunkName: "component---src-pages-flow-features-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-guides-js": () => import("./../../../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-large-data-orchestration-js": () => import("./../../../src/pages/large-data-orchestration.js" /* webpackChunkName: "component---src-pages-large-data-orchestration-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-market-map-js": () => import("./../../../src/pages/market-map.js" /* webpackChunkName: "component---src-pages-market-map-js" */),
  "component---src-pages-marketplace-js": () => import("./../../../src/pages/marketplace.js" /* webpackChunkName: "component---src-pages-marketplace-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-referrals-js": () => import("./../../../src/pages/referrals.js" /* webpackChunkName: "component---src-pages-referrals-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-sla-js": () => import("./../../../src/pages/sla.js" /* webpackChunkName: "component---src-pages-sla-js" */),
  "component---src-pages-solutions-comparison-js": () => import("./../../../src/pages/solutions-comparison.js" /* webpackChunkName: "component---src-pages-solutions-comparison-js" */),
  "component---src-pages-solutions-physical-retailers-bopis-js": () => import("./../../../src/pages/solutions/physical-retailers/bopis.js" /* webpackChunkName: "component---src-pages-solutions-physical-retailers-bopis-js" */),
  "component---src-pages-stacked-3-0-ebook-js": () => import("./../../../src/pages/stacked-3.0-ebook.js" /* webpackChunkName: "component---src-pages-stacked-3-0-ebook-js" */),
  "component---src-pages-startups-js": () => import("./../../../src/pages/startups.js" /* webpackChunkName: "component---src-pages-startups-js" */),
  "component---src-pages-subprocessors-js": () => import("./../../../src/pages/subprocessors.js" /* webpackChunkName: "component---src-pages-subprocessors-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-the-story-of-automation-js": () => import("./../../../src/pages/the-story-of-automation.js" /* webpackChunkName: "component---src-pages-the-story-of-automation-js" */),
  "component---src-pages-uapi-competitive-pricing-js": () => import("./../../../src/pages/uapi-competitive-pricing.js" /* webpackChunkName: "component---src-pages-uapi-competitive-pricing-js" */),
  "component---src-pages-unified-api-demo-js": () => import("./../../../src/pages/unified-api-demo.js" /* webpackChunkName: "component---src-pages-unified-api-demo-js" */),
  "component---src-pages-unified-api-js": () => import("./../../../src/pages/unified-api.js" /* webpackChunkName: "component---src-pages-unified-api-js" */),
  "component---src-pages-use-cases-js": () => import("./../../../src/pages/use-cases.js" /* webpackChunkName: "component---src-pages-use-cases-js" */),
  "component---src-templates-abm-banking-post-js": () => import("./../../../src/templates/abm-banking/post.js" /* webpackChunkName: "component---src-templates-abm-banking-post-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog/post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-post-seo-js": () => import("./../../../src/templates/blog/post-seo.js" /* webpackChunkName: "component---src-templates-blog-post-seo-js" */),
  "component---src-templates-case-study-post-js": () => import("./../../../src/templates/case-study/post.js" /* webpackChunkName: "component---src-templates-case-study-post-js" */),
  "component---src-templates-competitors-embedded-post-js": () => import("./../../../src/templates/competitors/embedded/post.js" /* webpackChunkName: "component---src-templates-competitors-embedded-post-js" */),
  "component---src-templates-custom-pages-post-js": () => import("./../../../src/templates/custom-pages/post.js" /* webpackChunkName: "component---src-templates-custom-pages-post-js" */),
  "component---src-templates-embedded-in-the-wild-post-js": () => import("./../../../src/templates/embedded-in-the-wild/post.js" /* webpackChunkName: "component---src-templates-embedded-in-the-wild-post-js" */),
  "component---src-templates-embedded-persona-post-js": () => import("./../../../src/templates/embedded-persona/post.js" /* webpackChunkName: "component---src-templates-embedded-persona-post-js" */),
  "component---src-templates-guides-post-js": () => import("./../../../src/templates/guides/post.js" /* webpackChunkName: "component---src-templates-guides-post-js" */),
  "component---src-templates-industries-post-js": () => import("./../../../src/templates/industries/post.js" /* webpackChunkName: "component---src-templates-industries-post-js" */),
  "component---src-templates-integration-post-js": () => import("./../../../src/templates/integration/post.js" /* webpackChunkName: "component---src-templates-integration-post-js" */),
  "component---src-templates-marketplace-recipe-js": () => import("./../../../src/templates/marketplace/recipe.js" /* webpackChunkName: "component---src-templates-marketplace-recipe-js" */),
  "component---src-templates-marketplace-workflow-js": () => import("./../../../src/templates/marketplace/workflow.js" /* webpackChunkName: "component---src-templates-marketplace-workflow-js" */),
  "component---src-templates-paid-ads-post-js": () => import("./../../../src/templates/paid-ads/post.js" /* webpackChunkName: "component---src-templates-paid-ads-post-js" */),
  "component---src-templates-use-cases-automation-post-js": () => import("./../../../src/templates/use-cases/automation/post.js" /* webpackChunkName: "component---src-templates-use-cases-automation-post-js" */),
  "component---src-templates-use-cases-embedded-post-js": () => import("./../../../src/templates/use-cases/embedded/post.js" /* webpackChunkName: "component---src-templates-use-cases-embedded-post-js" */)
}

